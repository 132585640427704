/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from "react";

function useInterval(callback, delay, stopCount) {
  let counter = 0;
  let id;
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      counter += 1;
      savedCallback.current();
      if (counter === stopCount) {
        clearInterval(id);
      }
    }
    if (delay !== null) {
      id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default useInterval;
