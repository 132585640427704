import { ThemeProvider, CssBaseline, createTheme, Container } from "@mui/material";
import theme from "assets/theme";
import { Route, Routes } from "react-router-dom";
import Home from "screens/Home/Container";
import Camera from "screens/Camera/Container";
import Result from "screens/Result/Container";
import routes, { RequireAuth } from "routes";
import Formulation from "screens/Formulation/Container";

function App() {
  const muiTheme = createTheme(theme);

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Routes>
        <Route
          exact
          path="/"
          // element={<Home stepName="Step 1. Calibration" navigateTo="/camera/calibration" />}
          element={
            <Home stepName="Step 1. Color Identification" navigateTo="/camera/identification" />
          }
        />
        {/* <Route
          exact
          path="/camera/calibration"
          element={
            <Camera
              stepName="Step 1. Calibration"
              imageName="referenceImage"
              navigateTo="/identification"
            />
          }
        /> */}
        <Route
          exact
          path="/identification"
          element={
            <Home stepName="Step 1. Color Identification" navigateTo="/camera/identification" />
          }
        />
        <Route
          exact
          path="/camera/identification"
          element={
            <Camera
              stepName="Step 1. Color Identification"
              imageName="originalImage"
              navigateTo="/result"
            />
          }
        />
        <Route
          exact
          path="/result"
          element={<Result stepName="Step 2. Color Formulation" navigateTo="/formulation" />}
        />
        <Route
          exact
          path="/formulation"
          element={<Formulation stepName="Formulation" navigateTo="/" />}
        />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
