import Header from "components/Header/Header";
import { Button, Divider, IconButton, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import CameraswitchRoundedIcon from "@mui/icons-material/CameraswitchRounded";
import CircleIcon from "@mui/icons-material/Circle";
import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { writeToLocalStorage } from "services/localStorage";
import uploadToS3 from "services/uploadToS3";
import { useNavigate } from "react-router-dom";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

function View({ stepName, imageName, navigateTo }) {
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
  const [imgSrc, setImgSrc] = useState(null);
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const videoConstraints = {
    facingMode: { exact: facingMode },
    width: { min: 640 },
    height: { min: 360 },
    // aspectRatio: 0.6666666667
  };
  const handleRotate = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    );
  }, []);
  // const capture = useCallback(() => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   setImgSrc(imageSrc);
  // }, [webcamRef, setImgSrc]);

  const capture = async () => {
    const imageSrc = await webcamRef.current.getScreenshot();
    const response = await uploadToS3(imageSrc);
    await writeToLocalStorage(imageName, response.Location);
    await setImgSrc(imageSrc);
    if (response) {
      navigate(navigateTo, { replace: true });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box>
        <Header title="Paint Color Detection and Formulation" />
        <Typography
          variant="h5"
          color="primary"
          sx={{ fontWeight: 500, textAlign: "center", padding: "5px" }}
        >
          {stepName}
        </Typography>
        <Divider />
      </Box>
      <Box>
        <Button onClick={handleRotate} style={{ position: "absolute", zIndex: 999 }}>
          <CameraswitchRoundedIcon />
        </Button>{" "}
        <Webcam
          width="100%"
          height="400px"
          mirrored={facingMode === FACING_MODE_USER}
          audio={false}
          videoConstraints={videoConstraints}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
        />
      </Box>
      <Box>
        {" "}
        <Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              color="primary"
              onClick={capture}
              style={{
                padding: 0,
                border: "2px solid #22617E",
              }}
            >
              <CircleIcon sx={{ fontSize: "5.5rem", fontWeight: 900 }} />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h6" color="primary" sx={{ fontWeight: "500 !important" }}>
              Click Picture
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default View;
