/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer";

const s3 = new AWS.S3({
  accessKeyId: "AKIA5SFEKY3CPFYTDLZV",
  secretAccessKey: "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",
});

const upload = (params) =>
  s3
    .upload(params)
    .promise()
    .then(async (data) => data)
    .catch((err) => {
      throw err;
    });
const uploadJSONtoS3 = async (obj) => {
  console.log(obj);
  const folder = `paint`;
  // const fileName = `${uuidv4().replace(/\\-/g, "")}.jpg`;
  const buf = new Buffer.from(JSON.stringify(obj));
  const params = {
    Bucket: `xane-user-data`,
    Key: `${folder}/paint_feedback_quantity_data.json`, // File name you want to save as in S3
    Body: buf,
    ACL: "public-read",
    ContentType: `application/json`,
    ContentEncoding: "base64",
  };

  try {
    const response = await upload(params);

    if (response) {
      console.log(response);
      return response;
    }
  } catch (err) {
    alert(err);
    throw err;
  }
};

export default uploadJSONtoS3;
