/* eslint-disable no-alert */
/* eslint-disable no-unreachable */
const axios = require("axios");

const API_URL = "https://y4s9rc42m6.execute-api.ap-south-1.amazonaws.com/vda";

const headers = {
  // "x-origin": 1,
  // "x-version": 1,
  // "x-device-id": "vda.device",
  // "x-platform": 1,
  "Content-Type": "multipart/form-data",
};

const requestApi = async (resourcePath, method, params) => {
  const url = API_URL + resourcePath;

  if (resourcePath === "/auths/login") {
    delete headers["x-auth"];
  }
  let response;
  if (["POST", "PUT", "DELETE"].indexOf(method) > -1 && params) {
    response = await axios({ url, method, data: params, headers });
    return response.data;
  }
  response = await axios({ url, method, headers });
  return response.data;
};

export default requestApi;
