/* eslint-disable prettier/prettier */
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Box, Divider, Typography } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PieChart({ transfromDataForPie }) {
  const data = {
    labels: transfromDataForPie().labels,
    datasets: [
      {
        data: transfromDataForPie().pieData,
        backgroundColor: transfromDataForPie().color,
        borderColor: transfromDataForPie().color,
        borderWidth: 1,
      },
    ],
  };

  // return <Pie data={data} />
  return(
    <Box
    sx={{
      minWidth: 0,
      backgroundColor:"#f5f4f9",
      borderRadius: "5px",
      marginBottom:"10px",
      padding:"10px" ,
      boxShadow:
        "rgba(40, 100, 124, 0.25) 0px 54px 55px, rgba(40, 100, 124, 0.12)0px -12px 30px, rgba(40, 100, 124, 0.12) 0px 4px 6px, rgba(40, 100, 124, 0.17) 0px 12px 13px, rgba(40, 100, 124, 0.09) 0px -3px 5px",
    }}
  >
  <Typography
  sx={{
    textAlign: "center",
    color: "#28647c",
    margin: "5px",
    fontFamily: '"Poppins","Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 18,
  }}
>
  Formulation Chart (per 100 gram)
</Typography>
   <Divider/> 
  <Pie data={data}/>
  </Box>
  )

}
