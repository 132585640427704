/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable react/no-array-index-key */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Input, TextField, Typography } from "@mui/material";
import PieChart from "components/Charts/PieChart";
import uploadJSONtoS3 from "services/uploadJSONtoS3";
import { v4 as uuidv4 } from "uuid";

const BoxColorName = {
  AM5: "Jet black", // jet black
  AM13: "Med coarse aluminum", // aluminumAA
  AM20: "Violet", // Violet
  AM33: "Green gold", // Green gold
  AM17: "BRT aluminum", // BRT Aluminum
  AB160: "Basecoat binder",
  AB150: "Basecoat binder", // base color
  AM94: "Centari extra coarse aluminum", // aluminum
  AM735: "White xirallic pearl", // pearl white xirallic
  XB_47606: "Black", // black
  AM965: "SPEC COARSE ALUMINIUM", // SPEC COARSE ALUMINIUM
  AM724: "CENTARI RUTILE RED PEARL", // CENTARI RUTILE RED PEARL
  AM29: "LT BLUE", // LT BLUE
  AM3: "CENTARI CRYSTALLINE FROST", // CENTARI CRYSTALLINE FROST
  AM31: "CENTARI FAST GREEN LS", // CENTARI FAST GREEN LS
  AM28: "FAST BLUE", // FAST BLUE
  AM61: "CENTARI MASTERT. SCARLET RED", // CENTARI MASTERT. SCARLET RED
  _4530S: "CENTARI FLOP CONTROL AGENT",
  P425_998: "Coarse Silver Dollar",
  P425_992: "Basic Shining Coarse",
  P425_948: "HS Black",
  P425_922: "HS Lagoon Blue",
  P420_938: "Basic Tone Controller",
  P429_923: "Bright Maroon",
  P420_942: "Transoxide Red",
  P192_528: "High Aluminium Flip Controller",
  P426_HE01: "light red",
  P425_989: "Coarse Aluminium",
  P192_5600: "Basecoat Flip Controller",
  P429_967: "HS Deep Angle Black",
  P420_982: "Transoxide Yellow",
  P426_HE02: "Ultra Fine White",
  P420_939: "Almond",
  Q811M: "Silver Metallic Sparkle",
  Q550: "Yellowish Green",
  Q195: "white",
  Q160: "Black",
  Q811J: "Silver Metallic Sparkle",
  Q140: "Deep black",
  Q190: "Controller",
  Q065: "Connector",
  Q766: "Violet",
  Q679: "Violet Blue",
  Q811R: "Silver Metallic Extra Coarse",
  Q673: "Blue",
};

function FormulationTable({ data, boxColors, formulationName, originalImage }) {
  const [QuantityFeedback, setQuantityFeedback] = React.useState([]);
  const keys = Object.keys(data);

  const transfromDataForPie = () => {
    const labels = keys;
    const pieData = Object.values(data);
    const color = keys.map((key) => boxColors[key]);
    return {
      labels,
      pieData,
      color,
    };
  };
  const handleSubmit = async () => {
    const body = [
      {
        formulation_name: formulationName,
        date_of_submission: new Date(),
        id: uuidv4().replace(/\\-/g, ""),
        image_url: originalImage,
        quantity_feedback: QuantityFeedback,
      },
    ];
    // console.log(body);
    const PrevQuantityFeedback = await fetch(
      "https://xane-user-data.s3.ap-south-1.amazonaws.com/paint/paint_feedback_quantity_data.json"
    )
      .then((response) => response.json())
      .then((FeedbackData) => FeedbackData);
    const appendedElements = [...PrevQuantityFeedback, ...body];
    if (confirm("Please confirm to submit")) {
      uploadJSONtoS3(appendedElements);
      alert("Submitted");
    } else {
      // user clicked Cancel or closed the dialog, do not perform deletion
    }
  };
  const handleInputChange = (e, tintCode) => {
    setQuantityFeedback((prevProp) => ({
      ...prevProp,
      [tintCode]: e.target.value,
    }));
  };
  return (
    <Box sx={{ margin: "18px" }}>
      <PieChart transfromDataForPie={transfromDataForPie} />
      <Typography
        sx={{
          textAlign: "center",
          color: "#28647c",
          margin: "15px",
          fontFamily: '"Poppins","Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: 18,
        }}
      >
        Formulation Table (per 100 gram)
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          minWidth: 200,
          backgroundColor: "#f5f4f9",
          borderRadius: "5px",
          margin: "auto",
          //   margin: "10px",
          boxShadow:
            "rgba(40, 100, 124, 0.25) 0px 54px 55px, rgba(40, 100, 124, 0.12)0px -12px 30px, rgba(40, 100, 124, 0.12) 0px 4px 6px, rgba(40, 100, 124, 0.17) 0px 12px 13px, rgba(40, 100, 124, 0.09) 0px -3px 5px",
        }}
      >
        <Table sx={{ minWidth: 200 }}>
          <TableHead sx={{ backgroundColor: "#28647c", color: "white" }}>
            <TableRow>
              <TableCell
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: '"Poppins","Roboto", "Helvetica", "Arial", sans-serif',
                  fontSize: 14,
                }}
              >
                Tint Code
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: '"Poppins","Roboto", "Helvetica", "Arial", sans-serif',
                  fontSize: 14,
                }}
              >
                Quantity
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: '"Poppins","Roboto", "Helvetica", "Arial", sans-serif',
                  fontSize: 14,
                }}
              >
                Details
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: '"Poppins","Roboto", "Helvetica", "Arial", sans-serif',
                  fontSize: 14,
                }}
              >
                Quantity Feedback (g)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys.map((item, index) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  sx={{
                    // color: boxColors[item] === "#F8F6F0" ? "#000" : boxColors[item],
                    color: "#000",
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  {item}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    // color: boxColors[item] === "#F8F6F0" ? "#000" : boxColors[item],
                    color: "#000",
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  {data[item]}g
                </TableCell>
                <TableCell align="left" sx={{ width: "50%" }}>
                  <Box
                    sx={{
                      height: "11px",
                      backgroundColor: "#fff",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #28647c",
                      zIndex: 999,
                    }}
                  >
                    <Box
                      sx={{
                        height: "10px",
                        borderTopStyle: "1px solid red",
                        borderBottomStyle: "1px solid red",
                        borderLeftStyle: "1px solid red",
                        backgroundColor: boxColors[item],
                        width: `${Number(data[item])}%`,
                      }}
                    />
                  </Box>
                  {BoxColorName[item]}
                </TableCell>
                <TableCell>
                  <Box>
                    <Input
                      type="number"
                      sx={{ width: "100%", height: "100%", border: "0.5px solid #d4d4d4" }}
                      onChange={(e) => handleInputChange(e, item)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "1rem 0 1rem 0",
        }}
      >
        <Button variant="contained" sx={{ padding: 2, width: "150px" }} onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
}
export default FormulationTable;
