/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
import Header from "components/Header/Header";
import {
  Divider,
  IconButton,
  Typography,
  Card,
  CardMedia,
  CardHeader,
  Button,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

import FormatColorFillSharpIcon from "@mui/icons-material/FormatColorFillSharp";
import { Link } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function View({
  stepName,
  navigateTo,
  // referenceImage,
  originalImage,
  detectedColor,
  // referenceColor,
  // differenceColor,
  formulation,
  boxColors,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "auto",
      }}
    >
      <Box>
        <Header title="Paint Color Detection and Formulation" />
        <Typography
          variant="h5"
          color="primary"
          sx={{ fontWeight: 500, textAlign: "center", padding: "5px" }}
        >
          {stepName}
        </Typography>
        <Divider />
      </Box>
      {/* comment here 
      <Box mt={4}>
        <Card sx={{ width: "85vw" }}>
          <CardHeader
            sx={{ padding: 1 }}
            title={
              <Typography variant="body" color="primary" sx={{ textAlign: "center" }}>
                Reference Image
              </Typography>
            }
          />
          <CardMedia component="img" height="194" image={referenceImage} alt="Paella dish" />
        </Card>
      </Box>
      <Divider /> */}
      <Box mt={2}>
        <Card sx={{ width: "85vw" }}>
          <CardHeader
            sx={{ padding: 1 }}
            title={
              <Typography variant="body" color="primary" sx={{ textAlign: "center" }}>
                Original Image
              </Typography>
            }
          />
          <CardMedia
            component="img"
            height="194"
            image={originalImage}
            alt="Paella dish"
            onClick={() => {
              window.open(originalImage);
            }}
          />
        </Card>
      </Box>
      <Divider sx={{ width: "10px" }} />
      <Box
        sx={{
          display: "flex",
          // border: "2px solid black",
          width: "90vw",
          // flexDirection: "column",
          // justifyContent: "space-around",
          // width: "100%",
        }}
      >
        {/*
        <Box
          mt={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <Typography variant="body" color="primary" sx={{ textAlign: "center" }}>
            Reference color <br />
            <span style={{ color: referenceColor }}>{referenceColor}</span>
          </Typography>

          <Box
            sx={{
              height: "100px",
              boxShadow: `${referenceColor} 0px 50px 100px -20px, ${referenceColor} 0px 30px 60px -30px, ${referenceColor} 0px -2px 6px 0px inset`,
              backgroundColor: referenceColor,
              border: `1px solid ${referenceColor}`,
            }}
          />
        </Box> */}
        <Box
          mt={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <Typography variant="body" color="primary" sx={{ textAlign: "center" }}>
            Detected color <br />
            <span style={{ color: detectedColor }}>{detectedColor}</span>
          </Typography>

          <Box
            sx={{
              height: "100px",
              boxShadow: `${detectedColor} 0px 50px 100px -20px, ${detectedColor} 0px 30px 60px -30px, ${detectedColor} 0px -2px 6px 0px inset`,
              backgroundColor: detectedColor,
              border: `1px solid ${detectedColor}`,
            }}
          />
          {/* </Box> */}
        </Box>
      </Box>
      {/*
      <Box mt={5} sx={{ width: "100%", textAlign: "center" }}>
        <Typography variant="body" color="primary">
          Difference in Color {differenceColor}
        </Typography>
          </Box> */}
      <Box mt={5}>
        {" "}
        <Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              color="primary"
              aria-label="Proceed Button"
              // size="large"
              style={{
                padding: 0,
              }}
            >
              <FormatColorFillSharpIcon
                sx={{
                  fontSize: "5rem",
                  fontWeight: 700,
                  zIndex: 100,

                  marginBottom: "8px",
                }}
              />
              {/* <CheckCircleRoundedIcon
                  sx={{ fontSize: "5.5rem", fontWeight: 900, zIndex: 100, color: "green" }}
                /> */}
            </IconButton>
            {/* </Link> */}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              flexDirection: "column",
            }}
          >
            <Box sx={{ minWidth: 220, padding: "1rem" }}>
              <FormControl fullWidth>
                <InputLabel>Get Formulation</InputLabel>

                <Select label="Get Formulation" color="primary">
                  <Button>
                    <Link
                      to={navigateTo}
                      state={{
                        formulation: formulation[0],
                        boxColors,
                        formulationName: "Axalta",
                        originalImage,
                      }}
                      style={{
                        textDecoration: "none",
                        color: "#22617f",
                      }}
                    >
                      <MenuItem value={10}>Axalta formulation</MenuItem>
                    </Link>
                  </Button>
                  <Button>
                    <Link
                      to={navigateTo}
                      state={{
                        formulation: formulation[1],
                        boxColors,
                        formulationName: "Asian",
                        originalImage,
                      }}
                      style={{
                        textDecoration: "none",
                        color: "#22617f",
                      }}
                    >
                      <MenuItem value={10}>Asian formulation</MenuItem>
                    </Link>
                  </Button>
                  <Button>
                    <Link
                      to={navigateTo}
                      state={{
                        formulation: formulation[2],
                        boxColors,
                        formulationName: "AkzoNoble",
                        originalImage,
                      }}
                      style={{
                        textDecoration: "none",
                        color: "#22617f",
                      }}
                    >
                      <MenuItem value={10}>AkzoNoble formulation</MenuItem>
                    </Link>
                  </Button>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default View;
