/* eslint-disable no-empty */
/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
/* eslint-disable no-lonely-if */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-expressions */
import Swal from "sweetalert2";

import requestApi from "apiHandler/config";
import Loader from "components/Loader/Loader";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useInterval from "services/customHooks/useInterval";
import { readFromLocalStorage } from "services/localStorage";
import View from "./View";

const loaderTexts = {
  0: "Analyzing Response...",
  1: "Identifying Color...",
  2: "Formulating Tint Volumes...",
};

const convertToRGB = (value) => `rgb(${value[0]},${value[1]},${value[2]})`;
function Result(props) {
  const [loadingTextCount, setLoadingTextCount] = useState(0);
  const [detectedColor, setDetectedColor] = useState("");
  const [refColor, setRefColor] = useState("");
  const [diffColor, setDiffColor] = useState("");
  const [formulationValue, setFormulationValue] = useState("");
  const [boxColors, setBoxColors] = useState("");
  const [fetchLoading, setFetchloading] = useState(true);
  const [referenceImage] = useState(readFromLocalStorage("referenceImage"));
  const [originalImage] = useState(readFromLocalStorage("originalImage"));
  useEffect(() => {
    (async () => {
      setFetchloading(true);
      const response = await requestApi("/paint", "POST", {
        // ref_img: referenceImage,
        car_img: originalImage,
      });
      if (response.code === "reference_detected") {
        const { rgb, formulation } = response.data;
        // const predict_color = convertToRGB(pred_rgb);
        // const difference_color = convertToRGB(diff);
        setFormulationValue(formulation);
        // setFormulationValue({
        //   5: "4.3",
        //   13: "3.3",
        //   20: "0.2",
        //   33: "0.5",
        //   94: "9.1",
        //   150: "64.8",
        //   735: "6.6",
        //   47606: "6.7",
        // });
        setBoxColors({
          AM5: "#0A0A0A", // jet black
          AM13: "#848789", // aluminum
          AM20: "#8F00FF", // Voilet
          AM33: "#d8bb78", // Green gold
          AM17: "#848789",
          AB160: convertToRGB(rgb), // base color
          AB150: "#F8F6F0", // base color // Base coat binder
          AM94: "#848789", // aluminum
          AM735: "#F8F6F0", // pearl white xirallic
          XB_47606: "#000", // black
          AM965: "#848789", // SPEC COARSE ALUMINIUM
          AM724: "#66061B", // CENTARI RUTILE RED PEARL
          AM29: "#ADD8E6", // LT BLUE
          AM3: "#6D9BC3", // CENTARI CRYSTALLINE FROST
          AM31: "#00FF00", // CENTARI FAST GREEN LS
          AM28: " #0000FF", // FAST BLUE
          AM61: "#FF2400", // CENTARI MASTERT. SCARLET RED
          _4530S: "#0F4DC9", // CENTARI FLOP CONTROL AGENT
          P425_998: "#BDB6AE", // Coarse Silver Dollar
          P425_992: "#a3b8b9", // Basic Shining Coarse
          P425_948: "#000", // HS Black
          P425_922: "#2B7ADB", // HS Lagoon Blue
          P420_938: "#F8F6F0", // Basic Tone Controller
          P429_923: "#800000", // Bright Maroon
          P420_942: "#6E0303", // Transoxide Red
          P192_528: "#848789", // High Aluminium Flip Controller
          P426_HE01: "#ffcccb", // light red(google search name and color)
          P425_989: "#B1B2B4", // Coarse Aluminium
          P192_5600: "#F8F6F0", // Basecoat Flip Controller
          P429_967: "#000", // HS Deep Angle Black
          P420_982: "#e49129", // Transoxide Yellow ,
          P426_HE02: "#FFFFFF", // Ultra Fine White
          P420_939: "#e7ceb8", // Almond(google search name and color)
          Q811M: "#808080", // Silver Metallic Sparkle
          Q550: "#9ACD32", // Yellowish Green
          Q195: "#fff", // white
          Q160: "#000", // Black
          Q811J: "#808080", // Silver Metallic Sparkle
          Q140: "#000", // Deep black
          Q190: "#f5f5f5", // Controller
          Q065: "#f5f5f5", // Connector
          Q766: "#8F00FF", // Violet
          Q679: "#324ab2", // Violet Blue
          Q811R: "#808080", // Silver Metallic Extra Coarse
          Q673: "#0000FF", // Blue
        });
        setDetectedColor(convertToRGB(rgb));
        // setRefColor(reference_color);

        setFetchloading(false);
      } else {
        if (response && response.message) {
          Swal.fire({
            icon: "error",
            text: response.message,
          }).then(() => {
            window.location = "/";
          });
        }
      }
    })();
  }, []);

  useInterval(
    () => {
      setLoadingTextCount(loadingTextCount + 1);
    },
    2000,
    3
  );
  return !fetchLoading && loadingTextCount > 2 ? (
    <View
      {...props}
      // referenceImage={referenceImage}
      originalImage={originalImage}
      detectedColor={detectedColor}
      // referenceColor={refColor}
      // differenceColor={diffColor}
      formulation={formulationValue}
      boxColors={boxColors}
    />
  ) : (
    <Loader loadingText={loaderTexts[loadingTextCount]} />
  );
  // return ;
}

export default Result;
