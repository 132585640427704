import { Box, Typography } from "@mui/material";
import Header from "components/Header/Header";
import FormulationTable from "components/Table/FormulationTable";
import React from "react";

function View({ formulation, navigateTo, boxColors, formulationName, originalImage }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box>
        <Header title="Paint Color Detection and Formulation" />
        <FormulationTable
          data={formulation}
          boxColors={boxColors}
          formulationName={formulationName}
          originalImage={originalImage}
        />
      </Box>
    </Box>
  );
}

export default View;
