import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import "./loader.css";

export default function Loader({ loadingText }) {
  return (
    <div>
      <div className="lds-spinner">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>

      <Typography
        variant="h6"
        color="primary"
        sx={{
          fontWeight: "500 !important",
          textAlign: "center",
          position: "absolute",
          top: "70%",
          left: "50%",
          width: "100%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {loadingText || "loading..."}
      </Typography>
    </div>
  );
}
