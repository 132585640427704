import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import View from "screens/Formulation/View";
import FormulatingProgressBar from "components/FormulatingProgress/FormulatingProgressBar";
import useInterval from "services/customHooks/useInterval";

const loaderTexts = {
  0: "Convincing the AI to paint the wall",
  1: "Pushing pixels...",
  2: "Formulating values..",
  3: "Didn't know paint dried so quickly.",
};

function Formulation(props) {
  const [loadingTextCount, setLoadingTextCount] = useState(0);

  const location = useLocation();
  const formulation = location.state?.formulation;
  const boxColors = location.state?.boxColors;
  const formulationName = location.state?.formulationName;
  const originalImage = location.state?.originalImage;

  useEffect(() => {
    if (!location.state || !location.state.formulation) {
      window.location = "/result";
    }
  }, []);

  useInterval(
    () => {
      setLoadingTextCount(loadingTextCount + 1);
    },
    2000,
    4
  );

  return loadingTextCount > 3 ? (
    <View
      {...props}
      formulation={formulation}
      boxColors={boxColors}
      formulationName={formulationName}
      originalImage={originalImage}
    />
  ) : (
    <FormulatingProgressBar loadingText={loaderTexts[loadingTextCount]} />
  );
  // return ;
}

export default Formulation;
