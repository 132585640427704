/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
import { IconButton, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import Header from "components/Header/Header";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import React from "react";
import { Link } from "react-router-dom";

function View({ stepName, navigateTo }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box>
        <Header title="Paint Color Detection and Formulation" />
      </Box>
      <Box>
        {" "}
        <Typography variant="h5" color="primary" sx={{ fontWeight: 900 }}>
          {stepName}
        </Typography>
      </Box>
      <Box>
        {" "}
        <Stack>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Link to={navigateTo}>
              <IconButton
                color="primary"
                aria-label="Proceed Button"
                size="large"
                style={{
                  backgroundColor: "#22617E",
                  color: "white",
                }}
              >
                <ArrowForwardRoundedIcon sx={{ fontSize: "3rem", fontWeight: 900 }} />
              </IconButton>
            </Link>
          </Box>
          <Box>
            <Typography color="primary" variant="h6" sx={{ fontWeight: "500 !important" }}>
              Proceed
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default View;
