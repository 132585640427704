/** 
  All of the routes for the kai App are added here,
  You can add a new route, customize the routes and delete the routes here.
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

//  layouts
import { lazy, Suspense } from "react";
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
import { useLocation, Navigate } from "react-router-dom";
import Loader from "components/Loader/Loader";
import ErrorBoundary from "components/ErrorBoundary";

const Home = lazy(() => import("./screens/Home/Container"));
const Camera = lazy(() => import("./screens/Camera/Container"));
const Result = lazy(() => import("./screens/Result/Container"));

function LazyHome(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Home {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyCamera(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Camera {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyResult(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Result {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    route: `/home`,
    layout: "app",
    component: <LazyHome />,
  },
  {
    type: "collapse",
    name: "Camera",
    key: "camera",
    icon: <Icon fontSize="small">Camera</Icon>,
    route: `/camera`,
    layout: "app",
    component: <LazyCamera />,
  },
  {
    type: "collapse",
    name: "Result",
    key: "result",
    icon: <Icon fontSize="small">Result</Icon>,
    route: `/result`,
    layout: "app",
    component: <LazyResult />,
  },
];

export function RequireAuth({ children }) {
  //   const authed = !!cookiesManipulator.getAuth().token;
  const authed = true;

  const location = useLocation();

  return authed === true ? (
    children
  ) : (
    <Navigate to="/auth/login" replace state={{ path: location.pathname }} />
  );
}
RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default routes;
