import React from "react";
import "components/FormulatingProgress/formulatingProgressBar.css";
import { Typography } from "@mui/material";

function FormulatingProgressBar({ loadingText }) {
  return (
    <div className="parent">
      <div className="mosaic-loader">
        <div className="cell d-0" />
        <div className="cell d-1" />
        <div className="cell d-2" />
        <div className="cell d-3" />
        <div className="cell d-1" />
        <div className="cell d-2" />
        <div className="cell d-3" />
        <div className="cell d-4" />
        <div className="cell d-2" />
        <div className="cell d-3" />
        <div className="cell d-4" />
        <div className="cell d-5" />
        <div className="cell d-3" />
        <div className="cell d-4" />
        <div className="cell d-5" />
        <div className="cell d-6" />
      </div>
      <Typography
        variant="h6"
        color="primary"
        sx={{
          fontWeight: "500 !important",
          textAlign: "center",
          position: "absolute",
          top: "80%",
          left: "50%",
          width: "100%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {loadingText || "loading..."}
      </Typography>
    </div>
  );
}

export default FormulatingProgressBar;
