/* eslint-disable react/destructuring-assignment */
import Loader from "components/Loader/Loader";
import React, { useState } from "react";
import View from "screens/Home/View";
import useInterval from "services/customHooks/useInterval";

// const loaderTexts = {
//   0: "Calibrating...",
//   // 0: "identification....",
// };

function Home(props) {
  // const [loadingTextCount, setLoadingTextCount] = useState(0);
  // useInterval(
  //   () => {
  //     setLoadingTextCount(loadingTextCount + 1);
  //   },
  //   2000,
  //   1
  // );

  // return props.navigateTo === "/camera/identification" && loadingTextCount < 1 ? (
  //   <Loader loadingText={loaderTexts[loadingTextCount]} />
  // ) : (
  //   <View {...props} />
  // );
  return <View {...props} />;
}

export default Home;
